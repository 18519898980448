import { useContext } from 'react';
import { Context } from '@components/Context';
import getOmsUrl from '@utils/getOmsUrl';
import useFetchImplementation from '@components/shared/useFetchImplementation';

export default function useFetch(klavioKey?: string) {
  const { state } = useContext(Context);
  const { firebaseAuth } = state;
  const omsUrl = getOmsUrl() ?? '';

  return useFetchImplementation(firebaseAuth, omsUrl, klavioKey);
}
