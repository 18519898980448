import { PurchaseItem, ShippingAddress } from '@dayetopia/types';
import { User } from 'firebase/auth';
import { FormikValues } from 'formik';
import { getFromLocalStorage, setOnLocalStorage } from '@components/shared/utilLocalStorage';
import { BRANDBASSADOR_PIXEL_URL } from '@constants';
import { BoxItem, CartItem, CartItemType, ProductCode, Promotion, SubscriptionCartItem, TrackingGoogleProduct } from '@contracts';
import { getProductCategory, getProductTitle } from '@data/products';
import getOmsUrl from '@utils/getOmsUrl';
import { encryptEmail } from '@utils/helpers';
import { getFromStorage } from './sessionStorage';

const facebookPixelId = '318121055701147';
const pinterestId = '549762433791';
const scriptDelay = 0;
const eventDelay = scriptDelay + 1000;
const pageTrackDelay = scriptDelay + 1000;
const oms = getOmsUrl();
const isDev = oms?.includes('localhost') || oms?.includes('staging');
const genRanHex = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getCurrencyCode = () => {
  const storedCurrency = getFromLocalStorage('currency');
  const currency = storedCurrency ? JSON.parse(storedCurrency) : '';
  return currency.letterCode || 'GBP';
};

export const trackEvent = async (event: string, properties?: { [key: string]: any }, delayTracking: boolean = false) => {
  if (delayTracking) await delay(eventDelay);
  const eventData = { event, ...properties };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);
};

export const identifyUser = async (email: string) => {
  await delay(eventDelay);

  const encryptedId = await encryptEmail(email);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login',
    userId: encryptedId
  });
};

/**
 * Creates a customer object for tracking purposes.
 *
 * @param {string} email - The customer's email address.
 * @param {boolean} includePastOrdersInfo - Whether to include information about past orders.
 * @param {ShippingAddress} [shippingAddress] - Optional shipping address information.
 * @returns {Object} An object containing customer information for tracking.
 */
export function createCustomerObject(email: string, includePastOrdersInfo: boolean, shippingAddress?: ShippingAddress) {
  const customerObject: any = {
    email,
    email_address: email
  };

  if (includePastOrdersInfo) {
    const pastOrdersCount = Number(getFromStorage('userPurchasesCount') || '0');
    const isNewCustomer = pastOrdersCount === 0;
    customerObject.new = isNewCustomer ? 1 : 0;
    customerObject.past_orders = pastOrdersCount;
  }

  if (shippingAddress) {
    customerObject.phone_number = shippingAddress.phone ?? '';
    customerObject.address = {
      first_name: shippingAddress.firstName,
      last_name: shippingAddress.lastName,
      street: shippingAddress.street1,
      city: shippingAddress.city,
      postal_code: shippingAddress.postalCode,
      country: shippingAddress.country
    };
  }

  return customerObject;
}

export const productPageTrack = async (productCode: string, email: string, price: number = 0, productVariant?: BoxItem[]) => {
  await delay(pageTrackDelay);
  const currencyCode = getCurrencyCode();
  const productName = getProductTitle(productCode);
  const productCategory = getProductCategory(productCode);
  const modifiedProductCode = productCode === ProductCode.ScreeningKit ? ProductCode.VMSEssential : productCode;
  const cartItemId = getCartItemId(modifiedProductCode, productVariant);

  const trackingObject = {
    ecommerce: {
      items: [
        {
          item_name: productName,
          item_id: cartItemId,
          item_group_id: getItemGroupId(modifiedProductCode as ProductCode),
          price,
          currency: currencyCode,
          item_brand: 'Daye',
          item_category: productCategory,
          item_variant: cartItemId,
          item_list_name: productCategory,
          index: 0,
          quantity: '1'
        }
      ]
    },
    customer: createCustomerObject(email, false)
  };

  trackEvent('view_item', trackingObject);
};

export const newsletterSignupTrack = async (location: string) => {
  trackEvent('newsletter-signup-track', {
    location
  });
};

export const checkoutTracking = async (cartItems: CartItem[], step: number, option?: string) => {
  await delay(eventDelay);
  const products = buildProductsArrayFromCartItems(cartItems);
  const actionField: { [key: string]: string | number } = { step };
  if (option) actionField.option = option;

  trackEvent('checkout', {
    ecommerce: {
      checkout: {
        actionField,
        products
      }
    }
  });
};

export const viewItemListTrack = async (cartItems: CartItem[], email: string) => {
  trackEvent('view_item_list', {
    ecommerce: {
      items: cartItems.map((cartItem: CartItem, index: number) => productFromCartItem(cartItem, index + 1))
    },
    customer: createCustomerObject(email, false)
  });
};

export const productClickTrack = async (productCode: string) => {
  const productName = getProductTitle(productCode);
  const modifiedProductCode = productCode === ProductCode.ScreeningKit ? ProductCode.VMSEssential : productCode;
  const productCategory = getProductCategory(productCode);

  trackEvent('product_click', {
    ecommerce: {
      click: {
        products: [
          {
            name: productName,
            id: modifiedProductCode,
            category: productCategory
          }
        ]
      }
    }
  });
};

export const addToCartTrack = async (addedCartItem: CartItem, email: string) => {
  await delay(scriptDelay);
  const currencyCode = getCurrencyCode();
  const productName = getProductTitle(addedCartItem.productCode);
  const cartItemPrice = addedCartItem.price ? addedCartItem.price.toFixed(2) : '0.00';

  const products: TrackingGoogleProduct[] = [];
  products.push(productFromCartItem(addedCartItem, 1));

  trackEvent('add_to_cart', {
    ecommerce: {
      currency: currencyCode,
      items: products
    },
    customer: createCustomerObject(email, false)
  });

  const payload = {
    eventName: 'AddToCart',
    eventId: 'add_to_cart',
    contentName: productName,
    contentIds: [addedCartItem.productCode],
    contentType: 'product',
    email: '',
    currency: currencyCode,
    value: cartItemPrice
  };
  await omsMarketingTrack(payload);
};

export function removeFromCartTrack(cartItem: CartItem, email: string) {
  const GA4Products = [];
  GA4Products.push(productFromCartItem(cartItem, 1));

  trackEvent('remove_from_cart', {
    ecommerce: {
      items: GA4Products
    },
    customer: createCustomerObject(email, false)
  });
}

export const startCheckoutTrack = async (cartItems: CartItem[], email: string) => {
  await delay(eventDelay);
  const currencyCode = getCurrencyCode();
  const products = buildProductsArrayFromCartItems(cartItems);

  trackEvent('begin_checkout', {
    ecommerce: {
      currency: currencyCode,
      items: products
    },
    customer: createCustomerObject(email, false)
  });
};

export const orderSuccessFeedbackTrack = async (rate: number, hearAboutUs: string, feedback: string, purchaseItems: PurchaseItem[]) => {
  const GA4Products = [];

  if (purchaseItems) {
    Object.entries(purchaseItems).forEach(([index, item]) => {
      const productName = getProductTitle(item.variant);
      const productCost = item.paidPrice?.toFixed(2);
      const subscriptionPlan = item.type.includes('subscription');
      const productObject = {
        item_name: productName ?? item.variant,
        item_id: item.variant,
        price: productCost,
        payment_option: subscriptionPlan ? (item as PurchaseItem).planCode : 'one-off',
        quantity: item.count.toString(),
        index
      };
      GA4Products.push(productObject);
    });
  }

  trackEvent('order_success_feedback', {
    ecommerce: {
      feedback: {
        rate,
        hearAboutUs,
        feedback,
        products: GA4Products
      }
    }
  });
};

export const viewCartTrack = (cartItems: CartItem[], email: string) => {
  trackEvent('view_cart', {
    ecommerce: {
      items: cartItems.map((cartItem: CartItem, index: number) => productFromCartItem(cartItem, index + 1))
    },
    customer: createCustomerObject(email, false)
  });
};

export const checkoutTrack = async (
  cartItems: CartItem[],
  email: string,
  orderInvoice: OrderInvoiceRecurly,
  orderCoupons: string,
  paymentMethod: string,
  shippingAddress: ShippingAddress
) => {
  if (!orderInvoice || !cartItems || cartItems.length === 0) {
    console.error('Missing orderInvoice or purchasedItems.');
    return;
  }

  await delay(scriptDelay);

  const currencyCode = getCurrencyCode();

  const dataObject = {
    ecommerce: {
      currency: currencyCode,
      value: orderInvoice.total,
      tax: orderInvoice.tax,
      shipping: orderInvoice.shipping,
      affiliation: 'Daye',
      transaction_id: orderInvoice.id,
      coupon: orderCoupons,
      payment_method: paymentMethod,
      items: cartItems.map((cartItem: CartItem, index: number) => productFromCartItem(cartItem, index + 1))
    },
    google_tag_params: {
      ecomm_pagetype: 'purchase',
      ecomm_prodid: cartItems.map((cartItem: CartItem) => cartItem.id.toString()),
      ecomm_totalvalue: orderInvoice.total,
      ecomm_totalvalue_tax_exc: orderInvoice.subtotal,
      items: cartItems.map((cartItem: CartItem) => ({ id: cartItem.id, price: cartItem.price, quantity: cartItem.quantity }))
    },
    customer: createCustomerObject(email, true, shippingAddress)
  };

  trackEvent('purchase', dataObject);

  const env = process.env.GATSBY_ACTIVE_ENV ?? 'development';
  if (env === 'prod') {
    const brandbassadorRefCode = getFromLocalStorage('link-bb-ref') || '';
    setOnLocalStorage('link-bb-ref', '');

    try {
      await brandbassadorTrack(orderInvoice.id, orderInvoice.total, currencyCode, brandbassadorRefCode, orderCoupons);
    } catch (err) {
      console.error(err);
    }
  }
};

export const viewPromotionTracking = async (promotions: Promotion[], email: string) => {
  trackEvent('view_promotion', {
    ecommerce: {
      items: promotions
    },
    customer: createCustomerObject(email, false)
  });
};

export const selectPromotionTracking = async (promotions: Promotion[], email: string) => {
  trackEvent('select_promotion', {
    ecommerce: {
      items: promotions
    },
    customer: createCustomerObject(email, false)
  });
};

const brandbassadorTrack = async (orderId: string, price: string, currency: string, refCode: string, couponCode: string) => {
  let url = `${BRANDBASSADOR_PIXEL_URL}?key=${process.env.BRANDBASSADOR_KEY}&order_id=${orderId}&total=${price}&currency=${currency}`;

  if (refCode) {
    url += `&ref=${refCode}`;
  } else if (couponCode) {
    url += `&code=${couponCode}`;
  }

  const options = {
    method: 'GET'
  };

  return fetch(url, options)
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const omsMarketingTrack = async (payload: any) => {
  if (isDev) return;
  const url = `${oms}/marketing/events`;
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const adjustedPayload = payload;

  adjustedPayload.userAgent = window.navigator.userAgent;
  adjustedPayload.sourceUrl = window.location.href;
  adjustedPayload.pixelId = facebookPixelId;
  adjustedPayload.eventTime = timestamp;
  adjustedPayload.pinterestId = pinterestId;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(adjustedPayload)
  };

  fetch(url, options).catch((err) => {
    console.error(err);
  });
};

/**
 * Determines the item group ID for a given product code.
 *
 * @param {ProductCode} productCode - The product code to categorize.
 * @returns {string} The corresponding item group ID or the original product code if no match is found.
 *
 * @example
 * getItemGroupId(ProductCode.TamponBox) // returns 'tampon_box'
 * getItemGroupId(ProductCode.Pads) // returns 'pads_box'
 * getItemGroupId(ProductCode.FestiveBundle) // returns 'bundle'
 * getItemGroupId(ProductCode.SomeOtherProduct) // returns 'SomeOtherProduct'
 */
export const getItemGroupId = (productCode: ProductCode): string => {
  const ItemGroupId = {
    TAMPON_BOX: 'tampon_box',
    PADS_BOX: 'pads_box',
    BUNDLE: 'bundle'
  } as const;

  const PAD_PRODUCT_CODES = new Set([
    ProductCode.Pads,
    ProductCode.PadsBox24L,
    ProductCode.PadsBox12L,
    ProductCode.PadsBox20R,
    ProductCode.PadsBox16SP
  ]);

  const BUNDLE_PRODUCT_CODES = new Set([ProductCode.FestiveBundle, ProductCode.VaginalHealthBundle, ProductCode.PeriodCareBundle]);

  if (productCode === ProductCode.TamponBox) {
    return ItemGroupId.TAMPON_BOX;
  }

  if (PAD_PRODUCT_CODES.has(productCode)) {
    return ItemGroupId.PADS_BOX;
  }

  if (BUNDLE_PRODUCT_CODES.has(productCode)) {
    return ItemGroupId.BUNDLE;
  }

  return productCode;
};

/**
 * Generates a unique cart item ID based on the product ID and box items.
 *
 * @param {string} id - The base product ID.
 * @param {BoxItem[] | undefined} boxItems - An array of box items, if applicable.
 * @returns {string} A unique cart item ID.
 *
 * @description
 * If boxItems are provided, the function generates a composite ID in the format:
 * "tampon_box_XcsYcrZnsWnr", where:
 * - X is the count of CBD super tampons
 * - Y is the count of CBD regular tampons
 * - Z is the count of naked super tampons
 * - W is the count of naked regular tampons
 * Only non-zero counts are included in the ID.
 *
 * @example
 * getCartItemId("tampon_box", [
 *   { key: "cbd-super", count: 8 },
 *   { key: "naked-regular", count: 4 }
 * ]) // returns "tampon_box_8cs4nr"
 *
 * getCartItemId("some_other_product", undefined) // returns "some_other_product"
 */
export const getCartItemId = (id: string, boxItems: BoxItem[] | undefined): string => {
  if (boxItems && boxItems.length > 0) {
    const parts = ['tampon_box'];
    const itemCounts = {
      'cbd-super': 0,
      'cbd-regular': 0,
      'naked-super': 0,
      'naked-regular': 0
    };

    boxItems.forEach((item) => {
      if (item.count > 0 && item?.key && item.key in itemCounts) {
        itemCounts[item.key as keyof typeof itemCounts] = item.count;
      }
    });

    if (itemCounts['cbd-super'] > 0) {
      parts.push(`${itemCounts['cbd-super']}cs`);
    }
    if (itemCounts['cbd-regular'] > 0) {
      parts.push(`${itemCounts['cbd-regular']}cr`);
    }
    if (itemCounts['naked-super'] > 0) {
      parts.push(`${itemCounts['naked-super']}ns`);
    }
    if (itemCounts['naked-regular'] > 0) {
      parts.push(`${itemCounts['naked-regular']}nr`);
    }

    return parts.join('_');
  }

  return id;
};

export const productFromCartItem = (cartItem: CartItem, index: number) => {
  const currencyCode = getCurrencyCode();
  const productName = getProductTitle(cartItem.productCode) ?? cartItem.productCode;
  const productCode = cartItem.productCode === ProductCode.ScreeningKit ? ProductCode.VMSEssential : cartItem.productCode;
  const cartItemPrice = cartItem.price ? cartItem.price?.toFixed(2) : '0';
  const totalCount = cartItem.boxItems?.reduce((total, item) => total + item.count, 0);
  const productVariant = getProductVariant(cartItem.productCode, totalCount);
  const cartItemId = getCartItemId(cartItem.id, cartItem.boxItems);

  return {
    item_name: productName,
    item_id: cartItemId,
    item_group_id: getItemGroupId(productCode),
    price: cartItemPrice,
    currency: currencyCode,
    item_brand: 'Daye',
    item_category: getProductCategory(productCode) ?? '',
    item_variant: productVariant,
    item_list_name: getProductCategory(productCode) ?? '',
    payment_option: cartItem.type === CartItemType.Subscription ? (cartItem as SubscriptionCartItem).selectedPlan?.planCode : 'one-off',
    index,
    quantity: cartItem.quantity?.toString()
  };
};

const buildProductsArrayFromCartItems = (cartItems: CartItem[]) => {
  const products = cartItems.map((cartItem: CartItem, index: number) => productFromCartItem(cartItem, index));
  return products;
};

const getProductVariant = (productCode: ProductCode, count?: number) => {
  let variant;
  if (count && (productCode.startsWith(ProductCode.TamponBox) || productCode === ProductCode.Pads)) {
    variant = productCode === ProductCode.Pads ? `${count}_pads` : `${count}_tampons`;
  }
  return variant;
};

export async function registrationTracking(email: string, currency: string, cartItems: CartItem[]) {
  await delay(scriptDelay);

  checkoutTracking(cartItems, 2, 'new-customer');

  const payload = {
    eventName: 'CompleteRegistration',
    eventId: 'complete_registration',
    email,
    value: 1,
    currency
  };
  await omsMarketingTrack(payload);
}

export const loginTracking = (cartItems: CartItem[]) => {
  checkoutTracking(cartItems, 2, 'existing-customer');
};

export async function addShippingInfoTracking(cartItems: CartItem[], email: string) {
  trackEvent('add_shipping_info', {
    ecommerce: {
      shipping_tier: 'standard',
      items: cartItems.map((cartItem: CartItem, index: number) => productFromCartItem(cartItem, index + 1))
    },
    customer: createCustomerObject(email, true)
  });
}

export async function addBillingInfoTracking(email: string, cartItems: CartItem[]) {
  await delay(scriptDelay);

  checkoutTracking(cartItems, 4);

  const payload = {
    eventName: 'AddPaymentInfo',
    eventId: 'add_payment_info',
    email
  };

  await omsMarketingTrack(payload);
}

export async function addPaymentInfoTracking(purchaseItems: PurchaseItem[], paymentType: string, email: string, shippingAddress: ShippingAddress) {
  const GA4Products = [];

  Object.entries(purchaseItems).forEach(([index, item]) => {
    const productName = getProductTitle(item.variant);
    const productCost = item.paidPrice?.toFixed(2);
    const totalCount = item.configuration?.reduce((total, nextItem) => total + nextItem.count, 0);
    const productVariant = getProductVariant(item.variant as ProductCode, totalCount);
    const subscriptionPlan = item.type?.includes('subscription');
    const productObject = {
      item_name: productName ?? item.variant,
      item_id: item.variant,
      price: productCost,
      item_brand: 'Daye',
      item_category: getProductCategory(item.variant) ?? '',
      item_variant: productVariant,
      item_list_name: getProductCategory(item.variant) ?? '',
      payment_option: subscriptionPlan ? (item as PurchaseItem).planCode : 'one-off',
      index: parseInt(index, 10) + 1,
      quantity: item.count.toString()
    };
    GA4Products.push(productObject);
  });

  trackEvent('add_payment_info', {
    ecommerce: {
      payment_type: paymentType,
      items: GA4Products
    },
    customer: createCustomerObject(email, true, shippingAddress)
  });
}

export async function reactivateSubscriptionTracking(content_id: string, content_name: string, value: number, currency: string, email: string) {
  await delay(scriptDelay);

  const payload = {
    eventName: 'Subscribe',
    eventId: 'subscribe',
    contentName: content_name,
    email,
    currency,
    value: value?.toFixed(2)
  };
  await omsMarketingTrack(payload);

  if (content_id === 'proviotics') {
    const eventId2 = genRanHex(12);

    const payload2 = {
      eventName: 'Proviotics Subscribe',
      eventId: eventId2,
      contentName: 'Proviotics Subscribe',
      email,
      currency,
      value: value?.toFixed(2)
    };
    await omsMarketingTrack(payload2);
  }
}

export const subscriptionCancelStartTrack = (productId?: string) => {
  trackEvent('subscription-cancel', { subscription_product: productId });
};

export const subscriptionStillCancelTrack = (productId?: string) => {
  trackEvent('subscription-still-cancel', { subscription_product: productId });
};

export const pageViewTrack = async (user: User | null) => {
  updateStoredReferrer();
  let encryptedId = '';
  if (user) {
    encryptedId = await encryptEmail(user.email ?? '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: encryptedId
    });
  }

  const payload = {
    eventName: 'page_view',
    eventId: 'page_view',
    email: encryptedId
  };
  omsMarketingTrack(payload);
};

export const PPCReportStartTrack = async () => {
  trackEvent('ppc-report-started');

  await delay(scriptDelay);
  const eventId = genRanHex(12);

  const payload = {
    eventName: 'PPC Report Started',
    eventId
  };

  omsMarketingTrack(payload);
};

export const PPCReportCompletedTrack = async () => {
  trackEvent('ppc-report-completed');

  await delay(scriptDelay);
  const eventId = genRanHex(12);

  const payload = {
    eventName: 'PPC Report Completed',
    eventId
  };

  omsMarketingTrack(payload);
};

export const PPCReportAnswersTrack = (values: FormikValues) => {
  const answersArray = [];
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      answersArray.push({ question: key, answer: Array.isArray(values[key]) ? JSON.stringify(values[key]) : values[key] });
    }
  }

  trackEvent('ppc-report-questionnaire', {
    ppcReport: {
      answersArray
    }
  });
};

export const updateStoredReferrer = () => {
  const { referrer } = document;
  let source;
  let medium;

  if (referrer.indexOf('google') !== -1) {
    source = 'google';
    if (referrer.indexOf('&gclid=') !== -1) {
      medium = 'cpc';
    } else if (referrer.indexOf('&utm_source=') !== -1) {
      medium = 'organic';
    }
  } else {
    return;
  }

  if (source && !getFromLocalStorage('referralSource')) {
    setOnLocalStorage('referralSource', source);
  }
  if (medium && !getFromLocalStorage('referralMedium')) {
    setOnLocalStorage('referralMedium', medium);
  }
};
