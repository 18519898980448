export const setOnLocalStorage = (key: string, value: any, json?: boolean) => {
  const storedValue = json ? JSON.stringify(value) : value;
  try {
    localStorage.setItem(key, storedValue);
  } catch (e) {
    // Error handling can be added here if needed
  }
};

export const getFromLocalStorage = (key: string, returnJson?: boolean) => {
  let value: string | JSON | null = null;

  try {
    value = localStorage.getItem(key);
  } catch (e) {
    // Error handling can be added here if needed
  }

  let returnValue = value;
  if (returnJson && value && value !== 'undefined') {
    try {
      returnValue = JSON.parse(value);
    } catch (e) {
      console.error('err', e);
      returnValue = value;
    }
  }

  return returnValue;
};

export function hydrateInitialState(initialState: any) {
  const newState = { ...initialState };
  Object.keys(newState).forEach((key) => {
    let value = getFromLocalStorage(key) ?? null;
    if (value && value !== 'undefined') {
      try {
        value = JSON.parse(value);
        newState[key] = value;
      } catch (e) {
        console.error('err', e);
      }
    }
  });
  return newState;
}

export const removeFromLocalStorage = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('localStorage is not accessible.');
  }
};

export function clearLocalStorage(initialState: any, persistOnLocal: string[]) {
  Object.keys(initialState).forEach((key) => {
    if (persistOnLocal.indexOf(key) === -1) {
      removeFromLocalStorage(key);
    }
  });
}
